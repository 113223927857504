<template>
  <ModalBase
    :name="$options.name"
    :title="compTitle"
    overlayClose
    :bodyPadding="false"
    modalContainerClass="max-w-4xl bg-white"
    @close="closeMatch"
  >
    <template #body>
      <UtilLoader :isLoading="matchApi.isLoading">
        <template #default>
          <table class="match-table">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{{ compUser.name }}</td>
              </tr>
              <tr>
                <th>Region</th>
                <td>{{ compUser.region }}, {{ compUser.country }}</td>
              </tr>
              <tr>
                <th>Registered</th>
                <td>{{ $date(compUser.registered).format('MM-YYYY') }}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{ compUser.mobile }} {{ compUser.phone }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ compUser.email }}</td>
              </tr>
              <tr v-for="action in compActions" :key="`action-${action.title}`">
                <th>{{ action.title }}</th>
                <td>
                  <a
                    v-for="(listing, i) in action.data"
                    :key="`${action.title}-${i}`"
                    class="text-sm block"
                    :href="`https://live-api.hectare.farm/admin/listing.php?ref=${listing.listingRef}`"
                    target="_blank"
                  >
                    {{ listing.quantity }} {{ listing.title }}, {{ listing.region }}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Cohorts</th>
                <td class="text-sm">
                  {{ compUser.cohorts.join(', ') }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </UtilLoader>
    </template>
  </ModalBase>
</template>

<script>
import Api from '@/lib/api'
import ModalBase from '@/components/modals/ModalBase'
import UtilLoader from '@/components/utils/UtilLoader'

export default {
  name: 'ModalMatch',
  components: {
    ModalBase,
    UtilLoader
  },
  props: {
    modalData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      matchApi: new Api()
    }
  },
  created() {
    this.getMatch()
  },
  computed: {
    compTitle() {
      if (this.matchApi.data.userRef) {
        return this.matchApi.data.name
      } else {
        return 'Loading...'
      }
    },

    compUser() {
      return this.matchApi.data
    },

    compActions() {
      if (!this.compUser.called) return []
      return [
        { title: 'Listed', data: this.compUser.listed },
        { title: 'Bought', data: this.compUser.bought },
        { title: 'Enquired', data: this.compUser.called.concat(this.compUser.messaged) },
        { title: 'Viewed', data: this.compUser.viewed }
      ]
    }
  },
  methods: {
    getMatch() {
      this.matchApi.get(`http://localhost:3000/local/analytics/v1/matchmaker/users/${this.modalData.userRef}`)
    },

    closeMatch() {}
  }
}
</script>

<style lang="scss" scoped>
.match-table {
  @apply w-full border-collapse;

  tr {
    @apply border-b;
  }

  td,
  th {
    @apply text-left py-1 px-4;
  }

  th {
    @apply font-medium align-top;
  }
}
</style>
